<template>
  <div>
    <div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-tooltip.hover.v-success
        title="Reasignar Técnico"
        variant="success"
        class="btn-icon"
        @click="abrirModalReasignacion"
      >
        <feather-icon icon="UserIcon" />
      </b-button>
    </div>
    <div>
      <b-modal
        id="modal-reasignaciones"
        v-model="verModalAsignar"
        title="Datos de Entrega de Luminaria"
        ok-only
        hide-footer
        size="sm"
        ok-title="Accept"
        no-close-on-backdrop
        @hide="limpiarModal"
      >
        <b-overlay
          :show="loading"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Técnico"
                      rules="required"
                    >
                      <label>
                        Técnico:
                      </label>
                      <v-select
                        v-model="tecnico"
                        label="nombre"
                        :options="tecnicosAPMIX"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr style="margin-top: -3px">
              <b-row>
                <b-col
                  style="margin-top: -25px"
                  class="d-flex justify-content-end"
                  cols="12"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2 mr-1"
                    @click="validationForm"
                  >
                    Guardar
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BForm, BOverlay, VBTooltip, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { updateCreateMigracion } from '@/utils/migracionLuminarias'
import { mensajeError, mensajeInformativo, notificacionError } from '@/utils/mensajes'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    vSelect,
    BOverlay,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    migracionesSeleccionadas: {
      type: Array,
      required: true,
    },
    tecnicosFiltrados: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,
      tecnico: null,
      verModalAsignar: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    tecnicosAPMIX() {
      return this.tecnicosFiltrados.filter(tecnico => tecnico.role === 'ADMINISTRATOR' || tecnico.role === 'administrador' || tecnico.role === 'migracion')
    },
  },
  methods: {
    abrirModalReasignacion() {
      if (this.migracionesSeleccionadas.length > 0) {
        const todasAsignadas = this.migracionesSeleccionadas.every(migracion => migracion.estado === 'ASIGNADA')
        if (todasAsignadas) {
          this.verModalAsignar = true
        } else {
          mensajeError('Las migraciones seleccionadas deben estar asignadas, para poder reasignarse')
        }
      } else {
        mensajeError('Debe seleccionar al menos una migración para reasignar')
      }
    },
    limpiarModal() {
      this.tecnico = null
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.guardarReasignacion()
        }
      })
    },
    guardarReasignacion() {
      this.loading = true

      const promesas = this.migracionesSeleccionadas.map(migracion => {
        const migracionActualizada = {
          id: migracion.id,
          tracking: [
            ...migracion.tracking,
            {
              estado: 'RE-ASIGNACIÓN',
              horaFecha: new Date(),
              usuario: {
                id: this.usuario.id,
                nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
              },
            },
          ],
          usuario: {
            id: this.tecnico.id,
            nombre: `${this.tecnico.firstName} ${this.tecnico.firstSurname}`,
            email: this.tecnico.email,
          },
        }
        return updateCreateMigracion(migracionActualizada, 1)
      })
      return Promise.all(promesas)
        .then(() => {
          this.$emit('actualizar-datos')
          mensajeInformativo('Actualizado', 'Reasignación realizada con éxito!')
        })
        .catch(error => {
          console.error(error)
          notificacionError('Algo ha salido mal :(')
        })
        .finally(() => {
          this.verModalAsignar = false
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
